// @import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$web-primary: mat-palette($mat-indigo);
$web-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-theme: mat-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
      warn: $web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($web-theme);

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

// *----------------------------------*\
//  Settings
// *----------------------------------*/

@import "1-settings/_border";
@import "1-settings/_breakpoints";
@import "1-settings/_colors";
@import "1-settings/_font-weight";
@import "1-settings/_shadows";
@import "1-settings/_spacing";
// @import "1-settings/_typography";

// *----------------------------------*\
//  Tools
// *----------------------------------*/

@import "2-tools/_breakpoints";
@import "2-tools/_colors";
@import "2-tools/_helpers";
@import "2-tools/_shadows";
@import "2-tools/_spacing";

// *----------------------------------*\
//  Generic
// *----------------------------------*/

@import "3-generic/_box-sizing";
@import "3-generic/_normalize";
@import "3-generic/_shared";
@import "3-generic/_scrollbar";
// @import "3-generic/_reset";

// *----------------------------------*\
//  Elements
// *----------------------------------*/

@import "4-elements/_links";
@import "4-elements/_page";
@import "4-elements/_table";
@import "4-elements/_p";
@import "4-elements/_type";

// *----------------------------------*\
//  Objects
// *----------------------------------*/

// @import '5-objects/_f-grid';
@import "5-objects/_flexboxgrid";

// *----------------------------------*\
//  Components
// *----------------------------------*/

@import "6-components/buttons";
@import "6-components/_table-input";
@import "./6-components/inputs";
@import "./6-components/menu";
@import "./6-components/tabela";
@import "./6-components/page-title";

// *----------------------------------*\
//  Trumps
// *----------------------------------*/

@import "7-trumps/_borders";
@import "7-trumps/_colors";
@import "7-trumps/_font-weight";
@import "7-trumps/_shadows";
@import "7-trumps/_spacing";
@import "7-trumps/_text-transforms";

@import "~ngx-toastr/toastr";

// *----------------------------------*\
//  Calendar
// *----------------------------------*/

button {
  outline: none;
  box-shadow: none;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.product-modal .modal-dialog {
  min-width: 1350px;
  padding: 27px 30px;
  border-radius: 8px;
}

.search-width {
  width: 40%;
}

.list-header-container {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #ffffffff;
  height: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}

.add-button {
  background-color: #5f27cd;
  color: #ffffff;
}

.add-second-button {
  background-color: #ffffff;
  color: #5f27cd;
}

h2 {
  font-weight: bold !important;
  color: #4e4e4ed8;
  font-size: 16px !important;
}

* {
  outline: none;
  font-family: "Inter", sans-serif;
}
