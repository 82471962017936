.btn {
    -webkit-appearance: none;
    border: none;
    outline: none;
    font-size: 11px;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    padding: 9px 24px;
    cursor: pointer;
    transition: all 250ms ease;
    border-radius: 3px;
    user-select: none;
    white-space: nowrap;
    flex-shrink: 0;
    height: max-content;
  
    &:hover,
    &:focus {
      box-shadow: shadow(4) !important;
      text-decoration: none;
    }
  
    &:disabled {
      color: #fff;
      opacity: 0.5;
  
      &:hover {
        box-shadow: none !important;
      }
    }
  
    > * + * {
      margin-left: 10px;
    }
  
    // Appearance
    &.-full {
      width: 100%;
    }
  
    &.-large {
      padding: 12px 32px;
      font-size: 14px;
    }
  
    &.-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  
    &.-circle.-small {
      width: 30px;
      height: 30px;
      font-size: 0.8em;
    }
  
    &.-square {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 0;
    }
  
    &.-square.-small {
      width: 30px;
      height: 30px;
    }
  
    &.-no-shadow {
      box-shadow: none !important;
  
      &:hover {
        box-shadow: none !important;
      }
    }
  
    &.-shadow {
      box-shadow: shadow(6);
    }
  
    &.-rounded {
      border-radius: 3em;
    }
  
    &.-transparent {
      background-color: transparent;
    }
  
    // Colors
    @each $color, $value in map-merge($theme-colors, $colors) {
      &.-#{$color} {
        background-color: $value;
        border: 1px solid $value;
      }
  
      &.-outline.-#{$color} {
        background-color: transparent;
        border: 1px solid $value;
        color: $value;
      }
  
      &.-text-#{$color} {
        color: $value;
      }
    }
  
    &.-muted {
      color: #686868;
    }
  }
  