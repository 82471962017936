/* You can add global styles to this file, and also import other style files */
@import './scss/styles.scss';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }



.mat-toolbar {
  margin-top: 5px;
}

.container {
  padding-left: 50px;
  padding-right: 50px;

  @media screen and (max-width: 980px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1396px !important;
  }
}

.example-spacer {
  flex: 1 1 auto;
}

.mat-checkbox-ripple .mat-ripple-element {
  background-color: #5f27cd !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5f27cd !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #5f27cd !important;
}
  
  