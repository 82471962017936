.clientes__pesquisa .mat-menu-panel {
  width: 300px;

  .mat-radio-button,
  .mat-radio-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
