// *----------------------------------*\
//  Settings > Colors
// *----------------------------------*/

$colors: (
  "white": #fff,
  "blue": #41b6e6,
  "blue-dark": #34657f,
  "blue-sap": #0a76d0,
  "pink-light": #ff7285,
  "pink": #ef60a3,
  "pink-dark": #a54570,
  "pink-vtex": #ff3366,
  "green": #00ab8e,
  "green-dark": #006d68,
  "green-lime": #78ca3c,
  "brown": #a08629,
  "brown-dark": #924c2e,
  "yellow": #fedd00,
  "orange": #f16423,
  "red": #ce0000,
  "black": #000,
  "gray-lightest": #f4f4f4,
  "gray-pagination": #8a8a8a,
  "gray-lightest2": #f9f9f9,
  "gray-light-blue": #dadee8,
  "gray-light-blue2": #d1d8f5,
  "gray": #b8b8b8,
  // *----------------------------------*\
    //  Seller Center Custom Collors:
    // *----------------------------------
    "gray-light": #efefef,
  "gray-dark": #bdbdbd,
  "gray-status": #999999,
  "green-success": #199b1e,
  "red-danger": #bd2a2a,
  "red-light": #ef5d60,
  "purple": #5f27cd,
  "purple-dark": #471d99,
  "yellow-middle": #f9db6d,
  "yellow-warning": #f5a200,
) !default;

$theme-colors: (
  primary: map-get($colors, "purple"),
  secondary: map-get($colors, "yellow-middle"),
  tertiary: map-get($colors, "orange"),
  success: map-get($colors, "green-success"),
  table-status-ok: map-get($colors, "gray-status"),
  info: map-get($colors, "blue"),
  warning: map-get($colors, "yellow-warning"),
  danger: map-get($colors, "red-danger"),
  tool: map-get($colors, "red-light"),
) !default;

$body-bg: map-get($colors, "gray-lightest");
$error-color: #ff4646;
$highlight-color: #f7f7f7;
$text-color: #464646;
$text-ligh-color: #757575;

$all-colors: map-merge(
  $colors,
  map-merge(
    $theme-colors,
    (
      "error": $error-color,
      "highlight": $highlight-color,
    )
  )
);

@mixin status($color) {
  color: $color;
  font-weight: 600;
  font-size: 16px;
}

.success {
  @include status(#199b1e);
}

.danger {
  @include status(#bd2a2a);
}

.warning {
  @include status(#f5a200);
}

.status-ok {
  @include status(#999999);
}

// *----------------------------------*\
//  Settings > Deprecated
// *----------------------------------*/

$text-colors: (
  "primary": #686868,
  "secondary": #a9abad,
  "dark": #464646,
) !default;

$c-primary: map-get($theme-colors, "primary");
$c-secondary: map-get($theme-colors, "secondary");
$c-tertiary: map-get($theme-colors, "tertiary");
$c-danger: map-get($theme-colors, "danger");
$c-login-background-color: #F2F0F9;

$c-blue: map-get($colors, "blue");
$c-blue-dark: map-get($colors, "blue-dark");
$c-pink: map-get($colors, "pink");
$c-pink-dark: map-get($colors, "pink-dark");
$c-green: map-get($colors, "green");
$c-green-dark: map-get($colors, "green-dark");
$c-brown: map-get($colors, "brown");
$c-brown-dark: map-get($colors, "brown-dark");
$c-red: map-get($colors, "red");
$c-red-light: map-get($colors, "pink-light");

$c-gray-lightest: map-get($colors, "gray-lightest");
$c-gray-light-blue: map-get($colors, "gray-light-blue");
$c-gray-light-blue2: map-get($colors, "gray-light-blue2");
$c-gray-light: map-get($colors, "gray-light");
$c-gray: map-get($colors, "default");
$c-gray-medium: map-get($colors, "gray-medium");
$c-gray-dark: map-get($colors, "gray-dark");

$c-text-primary: map-get($text-colors, "primary");
$c-text-secondary: map-get($text-colors, "secondary");
$c-text-dark: map-get($text-colors, "dark");

.hidden-product {
  background-color: #f4f4f4 !important;
  border-bottom: none;
}

.hidden-product-header {
  border-bottom: 1px solid #fff !important;
  font-weight: normal;
}
