.capitalize {
    text-transform: capitalize !important;
  
    &::first-letter {
      text-transform: capitalize !important;
    }
  }
  
  .lowercase {
    text-transform: lowercase !important;
  
    &::first-letter {
      text-transform: lowercase !important;
    }
  }
  
  .uppercase {
    text-transform: uppercase !important;
  
    &::first-letter {
      text-transform: uppercase !important;
    }
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .text-left {
    text-align: left !important;
  }
  