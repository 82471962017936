.table-input {
    font-size: 0.9rem;
    display: flex;
    align-items: center;
  
    &:focus-within {
      color: theme-color(primary);
    }
  
    &__input,
    &-input {
      font-size: 0.9rem;
  
      width: 100%;
      min-height: 2.2em;
      background-color: transparent;
      padding: 0;
      color: #686868;
      text-align: center;
      border: 1px solid transparent;
      transition: border-color 0.25s ease;
  
      &:hover {
        border-color: #dadee8;
      }
  
      &:focus {
        border-color: theme-color(primary);
        color: theme-color(primary);
      }
  
      &:disabled {
        border-color: transparent;
      }
  
      &:read-only {
        border-color: transparent;
      }
  
      &.ng-invalid.ng-touched:hover,
      &.ng-invalid.ng-touched:focus {
        border-color: theme-color(danger);
      }
  
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
  
      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  
    &__prefix,
    &-prefix {
      font-size: 0.9rem;
      display: inline-block;
      margin-right: 0.5rem;
    }
  
    &__suffix,
    &-suffix {
      font-size: 0.9rem;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
  